// ----------- import external dependencies ----------
import React, {useEffect, useState} from "react";
import { Form, Row, Input, Select, Col, Checkbox, Button } from "antd";

// ---------- import internal dependencies ----------
// import { getValueObject } from "../../../utils/helpers";
import {
  requestedFundingTimelineOptions,
  PurposeOfFunding,
  AmountRequestedPickList,
  newLeadsourceOptions,
  LeadSource
} from "../../../utils/dropdown-values";
import { phoneRegex } from "../../../utils/regexCheckers";
import { formatPhone } from "../../../utils/helpers";

function Step1({ cb, defaultValues }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [source, setSource] = useState('');

function handleChange(e){
  console.log(e);
  console.log('Referral Source changed');
  setSource(e);
  
}

  /**
   * Handle submit form
   * @param {object} values
   */
  const submitCallBack = (values) => {
    console.log('MRA submitCallBack Values:', values);
    cb(values);
  };

  useEffect(() => {
    form.setFieldsValue({ ...defaultValues });
  },[]);

  return (
    <Form
      layout="vertical"
      className="lead-form"
      defaultValue={{ ...defaultValues }}
      size="large"
      form={form}
      name="step1-form"
      onFinish={submitCallBack}
    >
      <Row gutter={32} align="middle">
        <Col className="gutter-row" sm={24} md={12}>
          <Form.Item
            label="Amount Requested"
            name="AmountRequestedPickList"
            rules={[{ required: true }]}
          >
            <Select defaultValue="">
              {AmountRequestedPickList.map((option) => (
                <Option key={option.key} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" sm={24} md={12}>
          <Form.Item
            label="When do you Require Funding?"
            rules={[{ required: true }]}
            name="RequestedFundingTimeline"
          >
            <Select defaultValue="">
              {requestedFundingTimelineOptions.map((option) => (
                <Option key={option.key} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="First Name"
            name="FirstName"
            rules={[
              {
                required: true,
                message: "Please this field is required",
                pattern: new RegExp(/\w+/),
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Last Name"
            name="LastName"
            rules={[
              {
                required: true,
                message: "Please this field is required",
                pattern: new RegExp(/\w+/),
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Email Address"
            name="Email"
            rules={[
              {
                required: true,
                message: "Please use a valid email",
                pattern: new RegExp(/\S+@\S+\.\S+/),
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Phone Number"
            name="Phone"
            rules={[
              {
                required: true,
                message: "Please put a valid phone number",
                pattern: new RegExp(phoneRegex),
              },
            ]}
          >
            <Input placeholder="XXX-XXX-XXXX"  onChange={(eve) => {
                form.setFieldsValue({ Phone: formatPhone(eve.target.value) });
              }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Funding Purposes"
            rules={[{ required: true }]}
            name="PurposeOfFunding"
          >
            <Select defaultValue="">
              {PurposeOfFunding.map((option) => (
                <Option key={option.key} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Legal Business Name"
            name="DBAName"
            rules={[
              {
                required: true,
                message: "Please this field is required",
                pattern: new RegExp(/\w+/),
              },
            ]}
          >
            <Input placeholder="Eg. XXX enteprise" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Referral Source"
            rules={[{ required: true }]}
            name="newLeadsource"
          >
            <Select 
            defaultValue=""
            onChange={(values) => {handleChange(values)}}
            >

              {newLeadsourceOptions.map((option) => (
                <Option key={option.key} value={option.value} >
                  {option.label} 
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {(source === 'Other') && (
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Referral Source (Other)"
            name="referralSpecified"
            rules={[
              {
                required: true,
                message: "Please this field is required",
                pattern: new RegExp(/\w+/),
              },
            ]}
          >
            <Input placeholder="Referral Source" />
          </Form.Item>
        </Col> 
        )}
      
      {(source === 'Customer Referral') && (
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Referral Source (Customer Referral)"
            name="referralSpecified"
            rules={[
              {
                required: true,
                message: "Please this field is required",
                pattern: new RegExp(/\w+/),
              },
            ]}
          >
            <Input placeholder="Customer Referral" />
          </Form.Item>
        </Col> 
        )}
      
        <Col span={24}>
          <Form.Item
            name="continue"
            valuePropName="checked"
            rules={[
              {
                required: true,
                transform: value => (value || undefined),
                type: 'boolean', 
                message: "Please check the checkbox to continue",
              },
            ]}
          >
            <Checkbox
            // onChange={(eve) =>
            //   form.setFieldsValue({ continue: eve.target.checked })
            // }
            >
              <span className="funding-consent">
                Clicking ‘Continue’ constitutes granting consent for us to
                contact you regarding this application for funding.
              </span>
            </Checkbox>
          </Form.Item>          
            <span className="funding-consent" >
              Loans made or arranged pursuant to a California Financing Law License. License #: 60DBO-178699
            </span>
        </Col>
        <Col span={24}>
          <div className="funding-action">
            {/* <Button type="link" className="btn-tran" onClick={previous}>
              Previous
            </Button> */}
            <Button className="btn-blue" htmlType="submit">
              Continue
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Step1;
