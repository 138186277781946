import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
import Config from '../../../config';

const { PLAID_PUBLIC_KEY,
  PLAID_PRODUCTS,
  PLAID_COUNTRY_CODES,
  PLAID_WEBHOOKURL,
  PLAID_ENV } = Config;

export const connectToPlaidStart = () => ({
  type: types.CONNECT_TO_PLAID_START
});

export const connectToPlaidSuccess = payload => ({
  type: types.CONNECT_TO_PLAID_SUCCESS,
  payload
});

export const connectToPlaidFail = payload => ({
  type: types.CONNECT_TO_PLAID_FAIL,
  payload
});

export const connectToPlaidCleanup = () => ({
  type: types.CONNECT_TO_PLAID_CLEANUP
});

export const connectToPlaid = (payload, useCredentials=true) => async dispatch => {
  const Plaid = window.Plaid;

  const requestObj = {
    path: "plaid/create_link_token",
    method: "POST"
  }
  const cData = await AxiosCall(requestObj);

  //console.log('MRA cData:', cData);

  if(cData && cData.link_token){
    const plaidHandler = Plaid.create({
      //apiVersion: 'v2',
      //clientName: 'Altbanq',
      //env: PLAID_ENV,
      //product: PLAID_PRODUCTS.split(','),
      //key: PLAID_PUBLIC_KEY,
      //countryCodes: PLAID_COUNTRY_CODES.split(','),
      //webhook:PLAID_WEBHOOKURL,
      token: cData.link_token,
      onSuccess: async public_token => {
        const path = useCredentials ? 'plaid/set_access_token':'plaid/set_plaid_access_token_no_auth';
        try {
          dispatch(connectToPlaidStart());
          const requestObj = {
            path,
            method: "POST",
            data: {
              public_token,
              ...payload
            }
          }
          const data = await AxiosCall(requestObj);
          dispatch(connectToPlaidSuccess(data));
        } catch (err) {
          const error = ErrorHandler(err);          
          dispatch(connectToPlaidFail(error));
        }
      },
    });
    plaidHandler.open();
  }    
}
