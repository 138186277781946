const OppStage = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Submitted", label: "Submitted", value: "Submitted" },
  { key: "Re-Submission", label: "Re-Submission", value: "Re-Submission" },
  { key: "Revisit Offer", label: "Revisit Offer", value: "Revisit Offer" },
  { key: "Auto-Decline", label: "Auto-Decline", value: "Auto-Decline" },
  { key: "Application In", label: "Application In", value: "Application In" },
  {
    key: "Application Missing Info",
    label: "Application Missing Info",
    value: "Application Missing Info",
  },
  { key: "On Hold", label: "On Hold", value: "On Hold" },
  { key: "Offer Sent", label: "Offer Sent", value: "Offer Sent" },
  { key: "Underwriting", label: "Underwriting", value: "Underwriting" },
  { key: "Approved", label: "Approved", value: "Approved" },
  { key: "Declined", label: "Declined", value: "Declined" },
  {
    key: "Agreement Requested",
    label: "Agreement Requested",
    value: "Agreement Requested",
  },
  { key: "Agreement Sent", label: "Agreement Sent", value: "Agreement Sent" },
  {
    key: "Agreement Signed",
    label: "Agreement Signed",
    value: "Agreement Signed",
  },
  { key: "Completed File", label: "Completed File", value: "Completed File" },
  { key: "Funded", label: "Funded", value: "Funded" },
  {
    key: "Renewal Prospecting",
    label: "Renewal Prospecting",
    value: "Renewal Prospecting",
  },
  {
    key: "Renewal Requested",
    label: "Renewal Requested",
    value: "Renewal Requested",
  },
  { key: "Closed Lost", label: "Closed Lost", value: "Closed Lost" },
];

const IndustryOptions = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Agriculture", label: "Agriculture", value: "Agriculture" },
  { key: "Apparel", label: "Apparel", value: "Apparel" },
  { key: "Banking", label: "Banking", value: "Banking" },
  { key: "Biotechnology", label: "Biotechnology", value: "Biotechnology" },
  { key: "Chemicals", label: "Chemicals", value: "Chemicals" },
  { key: "Communications", label: "Communications", value: "Communications" },
  { key: "Construction", label: "Construction", value: "Construction" },
  { key: "Consulting", label: "Consulting", value: "Consulting" },
  { key: "Education", label: "Education", value: "Education" },
  { key: "Electronics", label: "Electronics", value: "Electronics" },
  { key: "Energy", label: "Energy", value: "Energy" },
  { key: "Engineering", label: "Engineering", value: "Engineering" },
  { key: "Entertainment", label: "Entertainment", value: "Entertainment" },
  { key: "Environmental", label: "Environmental", value: "Environmental" },
  { key: "Finance", label: "Finance", value: "Finance" },
  {
    key: "	Food & Beverage",
    label: "	Food & Beverage",
    value: "Food & Beverage",
  },
  { key: "Government", label: "Government", value: "Government" },
  { key: "Healthcare", label: "Healthcare", value: "Healthcare" },
  { key: "Hospitality	", label: "Hospitality	", value: "Hospitality	" },
  { key: "Insurance", label: "Insurance", value: "Insurance" },
  { key: "Machinery", label: "Machinery", value: "Machinery" },
  { key: "Manufacturing", label: "Manufacturing", value: "Manufacturing" },
  { key: "Media", label: "Media", value: "Media" },
  { key: "Not For Profit", label: "Not For Profit", value: "Not For Profit" },
  { key: "Other", label: "Other", value: "Other" },
  { key: "Recreation", label: "Recreation", value: "Recreation" },
  { key: "Retail", label: "Retail", value: "Retail" },
  { key: "Shipping", label: "Shipping", value: "Shipping" },
  { key: "Technology", label: "Technology", value: "Technology" },
  {
    key: "Telecommunications",
    label: "Telecommunications",
    value: "Telecommunications",
  },
  { key: "Transportation", label: "Transportation", value: "Transportation" },
  { key: "Utilities", label: "Utilities", value: "Utilities" },
];

const TypeOfEntityOptions = [
  { key: "None", label: "--None--", value: "" },
  { key: "Sole Prop", label: "Sole Prop", value: "Sole Prop" },
  { key: "Partnership", label: "Partnership", value: "Partnership" },
  { key: "S-Corp", label: "S-Corp", value: "S-Corp" },
  { key: "Corporation", label: "Corporation", value: "Corporation" },
  { key: "LLC", label: "LLC", value: "LLC" },
  { key: "LLP", label: "LLP", value: "LLP" },
];

const syndicationPPayableStatus = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Due", label: "Due", value: "Due" },
  { key: "Paid", label: "Paid", value: "Paid" },
  { key: "Cancelled", label: "Cancelled", value: "Cancelled" },
  {
    key: "Remittance Created",
    label: "Remittance Created",
    value: "Remittance Created",
  },
];

const syndicationManagementFeeOf = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Syndication", label: "Syndication", value: "Syndication" },
  { key: "Payback", label: "Payback", value: "Payback" },
  { key: "Investment", label: "Investment", value: "Investment" },
];

const syndicationUpfrontFeeOf = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Payback", label: "Payback", value: "Payback" },
  { key: "Funded", label: "Funded", value: "Funded" },
];

const syndicationDisbursementSchedule = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Daily", label: "Daily", value: "Daily" },
  { key: "Weekly", label: "Weekly", value: "Weekly" },
  { key: "Bi-Weekly", label: "Bi-Weekly", value: "Bi-Weekly" },
  { key: "Monthly", label: "Monthly", value: "Monthly" },
];

const paymentDisbursementSchedule = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Daily", label: "Daily", value: "Daily" },
  { key: "Weekly", label: "Weekly", value: "Weekly" },
  { key: "Bi-Weekly", label: "Bi-Weekly", value: "Bi-Weekly" },
  { key: "Monthly", label: "Monthly", value: "Monthly" },
];

const paymentUpfrontFeeOf = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Payback", label: "Payback", value: "Payback" },
  { key: "Funded", label: "Funded", value: "Funded" },
];

const paymentManagementFeeOf = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Payback", label: "Payback", value: "Payback" },
  { key: "Funded", label: "Funded", value: "Funded" },
];

const paymentTransactionType = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Fee", label: "Fee", value: "Fee" },
  { key: "Payment", label: "Payment", value: "Payment" },
  { key: "Adjustment", label: "Adjustment", value: "Adjustment" },
];

const paymentStatus = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Scheduled", label: "Scheduled", value: "Scheduled" },
  { key: "Pending", label: "Pending", value: "Pending" },
  { key: "Settled", label: "Settled", value: "Settled" },
  { key: "Failed", label: "Failed", value: "Failed" },
  { key: "Cleared", label: "Cleared", value: "Cleared" },
];

const paymentMethod = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "ACH", label: "ACH", value: "ACH" },
  { key: "Wire", label: "Wire", value: "Wire" },
  { key: "Check", label: "Check", value: "Check" },
  { key: "Split Funding", label: "Split Funding", value: "Split Funding" },
  { key: "Other", label: "Other", value: "Other" },
];

const offerPMTSchedule = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "Daily", label: "Daily", value: "Daily" },
  { key: "Weekly", label: "Weekly", value: "Weekly" },
  { key: "Bi-Weekly", label: "Bi-Weekly", value: "Bi-Weekly" },
  { key: "Bi-Monthly", label: "Bi-Monthly", value: "Bi-Monthly" },
  { key: "Monthly", label: "Monthly", value: "Monthly" },
];

const newLeadsourceOptions = [
  { key: "LinkedIn", label: "LinkedIn", value: "LinkedIn" },
  { key: "Instagram", label: "Instagram", value: "Instagram" },
  { key: "Facebook", label: "Facebook", value: "Facebook" },
  { key: "Search Engine", label: "Search Engine", value: "Search Engine"},
  { key: "Google Ads", label: "Google Ads", value: "Google Ads" },
  { key: "Blog Post", label: "Blog Post", value: "Blog Post" },
  { key: "Trustpilot", label: "Trustpilot", value: "Trustpilot" },
  { key: "Conference", label: "Conference", value: "Conference" },
  { key: "Networking Event", label: "Networking Event", value: "Networking Event" },
  { key: "Customer Referral", label: "Customer Referral", value: "Customer Referral" },
  { key: "Other", label: "Other", value: "Other" },
];

const offerDealType = [
  { key: "--None--", label: "--None--", value: "--None--" },
  { key: "MCA", label: "MCA", value: "MCA" },
  { key: "ACH", label: "ACH", value: "ACH" },
  { key: "Hybrid", label: "Hybrid", value: "Hybrid" },
  { key: "SBA", label: "SBA", value: "SBA" },
  { key: "Starter", label: "Starter", value: "Starter" },
];

const requestedFundingTimelineOptions = [
  { key: "Immediately", label: "Immediately", value: "Immediately" },
  { key: "1 Week", label: "1 Week", value: "1 Week" },
  { key: "30 Days", label: "30 Days", value: "30 Days" },
  {
    key: "More Than 30 Days",
    label: "More Than 30 Days",
    value: "More Than 30 Days",
  },
];

const HowDoYouPreferToBeContacted = [
  { key: "Phone", label: "Phone", value: "Phone" },
  { key: "Email", label: "Email", value: "Email" },
];

const BusinessIndustry = [
  { key: "Agriculture", label: "Agriculture", value: "Agriculture" },
  { key: "Apparel", label: "Apparel", value: "Apparel" },
  { key: "Banking", label: "Banking", value: "Banking" },
  { key: "Biotechnology", label: "Biotechnology", value: "Biotechnology" },
  { key: "Chemicals", label: "Chemicals", value: "Chemicals" },
  { key: "Communications", label: "Communications", value: "Communications" },
  { key: "Construction", label: "Construction", value: "Construction" },
  { key: "Consulting", label: "Consulting", value: "Consulting" },
  { key: "Education", label: "Education", value: "Education" },
  { key: "Electronics", label: "Electronics", value: "Electronics" },
  { key: "Energy", label: "Energy", value: "Energy" },
  { key: "Engineering", label: "Engineering", value: "Engineering" },
  { key: "Entertainment", label: "Entertainment", value: "Entertainment" },
  { key: "Environmental", label: "Environmental", value: "Environmental" },
  { key: "Finance", label: "Finance", value: "Finance" },
  {
    key: "Food & Beverage",
    label: "Food & Beverage",
    value: "Food & Beverage",
  },
  { key: "Government", label: "Government", value: "Government" },
  { key: "Healthcare", label: "Healthcare", value: "Healthcare" },
  { key: "Hospitality", label: "Hospitality", value: "Hospitality" },
  { key: "Insurance", label: "Insurance", value: "Insurance" },
  { key: "Machinery", label: "Machinery", value: "Machinery" },
  { key: "Manufacturing", label: "Manufacturing", value: "Manufacturing" },
  { key: "Media", label: "Media", value: "Media" },
  { key: "Not For Profit", label: "Not For Profit", value: "Not For Profit" },
  { key: "Other", label: "Other", value: "Other" },
  { key: "Recreation", label: "Recreation", value: "Recreation" },
  { key: "Retail", label: "Retail", value: "Retail" },
  { key: "Shipping", label: "Shipping", value: "Shipping" },
  { key: "Technology", label: "Technology", value: "Technology" },
  {
    key: "Telecommunications",
    label: "Telecommunications",
    value: "Telecommunications",
  },
  { key: "Transportation", label: "Transportation", value: "Transportation" },
  { key: "Utilities", label: "Utilities", value: "Utilities" },
];

const LegalEntityType = [
  {
    key: "Sole Proprietorship",
    label: "Sole Proprietorship",
    value: "Sole Proprietorship",
  },
  { key: "Partnership", label: "Partnership", value: "Partnership" },
  { key: "S-Corp", label: "S-Corp", value: "S-Corp" },
  { key: "Corporation", label: "Corporation", value: "Corporation" },
  { key: "LLC", label: "LLC", value: "LLC" },
  { key: "LLP", label: "LLP", value: "LLP" },
];

const PurposeOfFunding = [
  {
    key: "Working Capital",
    label: "Working Capital",
    value: "Working Capital",
  },
  { key: "Inventory", label: "Inventory", value: "Inventory" },
  { key: "Pay Bills", label: "Pay Bills", value: "Pay Bills" },
  {
    key: "Marketing/Advertising",
    label: "Marketing/Advertising",
    value: "Marketing/Advertising",
  },
  { key: "New Equipment", label: "New Equipment", value: "New Equipment" },
  { key: "New Location", label: "New Location", value: "New Location" },
  { key: "Expansion", label: "Expansion", value: "Expansion" },
  { key: "Other", label: "Other", value: "Other" },
];

const AmountRequestedPickList = [
  {
    key: "$0-$10,000",
    label: "$0-$10,000",
    value: "$0-$10,000",
  },
  {
    key: "$10,000-$25,000",
    label: "$10,000-$25,000",
    value: "$10,000-$25,000",
  },
  {
    key: "$25,000-$100,000",
    label: "$25,000-$100,000",
    value: "$25,000-$100,000",
  },
  {
    key: "$100,000-$250,000",
    label: "$100,000-$250,000",
    value: "$100,000-$250,000",
  },
  {
    key: "More than $250,000",
    label: "More than $250,000",
    value: "More than $250,000",
  },
];

const LeadSource = [
  { key: "Advertisement", label: "Advertisement", value: "Advertisement" },
  { key: "Customer Event", label: "Customer Event", value: "Customer Event" },
  {
    key: "Employee Referral",
    label: "Employee Referral",
    value: "Employee Referral",
  },
  { key: "Google AdWords", label: "Google AdWords", value: "Google AdWords" },
  { key: "Other", label: "Other", value: "Other" },
  { key: "Partner", label: "Partner", value: "Partner" },
  { key: "Purchased List", label: "Purchased List", value: "Purchased List" },
  { key: "Trade Show", label: "Trade Show", value: "Trade Show" },
  { key: "Webinar", label: "Webinar", value: "Webinar" },
  { key: "Website", label: "Website", value: "Website" },
];

const siSubmissionStatusOptions = [
  { key: "siSubmitted", label: "Submitted", value: "Submitted" },
  { key: "siRe-Submission", label: "Re-Submission", value: "Re-Submission" },
  { key: "siClosed - Lost", label: "Closed - Lost", value: "Closed - Lost" },
  {
    key: "siClosed - Archived",
    label: "Closed - Archived",
    value: "Closed - Archived",
  },
  { key: "siConvert", label: "Convert", value: "Convert" },
];

const siIndustryOptions = [
  { key: "siNone", label: "--None--", value: "" },
  { key: "siAgriculture", label: "Agriculture", value: "Agriculture" },
  { key: "siApparel", label: "Apparel", value: "Apparel" },
  { key: "siBanking", label: "Banking", value: "Banking" },
  { key: "siBiotechnology", label: "Biotechnology", value: "Biotechnology" },
  { key: "siChemicals", label: "Chemicals", value: "Chemicals" },
  { key: "siCommunications", label: "Communications", value: "Communications" },
  { key: "siConstruction", label: "Construction", value: "Construction" },
  { key: "siConsulting", label: "Consulting", value: "Consulting" },
  { key: "siEducation", label: "Education", value: "Education" },
  { key: "siElectronics", label: "Electronics", value: "Electronics" },
  { key: "siEnergy", label: "Energy", value: "Energy" },
  { key: "siEngineering", label: "Engineering", value: "Engineering" },
  { key: "siEntertainment", label: "Entertainment", value: "Entertainment" },
  { key: "siEnvironmental", label: "Environmental", value: "Environmental" },
  { key: "siFinance", label: "Finance", value: "Finance" },
  {
    key: "siFoodBeverage",
    label: "Food &amp; Beverage",
    value: "Food &amp; Beverage",
  },
  { key: "siGovernment", label: "Government", value: "Government" },
  { key: "siHealthcare", label: "Healthcare", value: "Healthcare" },
  { key: "siHospitality", label: "Hospitality", value: "Hospitality" },
  { key: "siInsurance", label: "Insurance", value: "Insurance" },
  { key: "siMachinery", label: "Machinery", value: "Machinery" },
  { key: "siManufacturing", label: "Manufacturing", value: "Manufacturing" },
  { key: "siMedia", label: "Media", value: "Media" },
  { key: "siNot For Profit", label: "Not For Profit", value: "Not For Profit" },
  { key: "siOther", label: "Other", value: "Other" },
  { key: "siRecreation", label: "Recreation", value: "Recreation" },
  { key: "siRetail", label: "Retail", value: "Retail" },
  { key: "siShipping", label: "Shipping", value: "Shipping" },
  { key: "siTechnology", label: "Technology", value: "Technology" },
  {
    key: "siTelecommunications",
    label: "Telecommunications",
    value: "Telecommunications",
  },
  { key: "siTransportation", label: "Transportation", value: "Transportation" },
  { key: "siUtilities", label: "Utilities", value: "Utilities" },
];

const siLegalEntityTypeOptions = [
  { key: "siNone", label: "--None--", value: "" },
  {
    key: "siSole Proprietorship",
    label: "Sole Proprietorship",
    value: "Sole Proprietorship",
  },
  { key: "siPartnership", label: "Partnership", value: "Partnership" },
  { key: "siS-Corp", label: "S-Corp", value: "S-Corp" },
  { key: "siCorporation", label: "Corporation", value: "Corporation" },
  { key: "siLLC", label: "LLC", value: "LLC" },
  { key: "siLLP", label: "LLP", value: "LLP" },
];

const siBusinessLocationOwnOrRentOptions = [
  { key: "siNone", label: "--None--", value: "" },
  { key: "siOwn", label: "Own", value: "Own" },
  { key: "siRent", label: "Rent", value: "Rent" },
];

const salutationOptions = [
  { key: "None", label: "--None--", value: "" },
  { key: "Mr.", label: "Mr.", value: "Mr." },
  { key: "Ms.", label: "Ms.", value: "Ms." },
  { key: "Mrs.", label: "Mrs.", value: "Mrs." },
  { key: "Dr.", label: "Dr.", value: "Dr." },
  { key: "Prof.", label: "Prof.", value: "Prof." },
];

const siSubmissionSourceOptions = [
  { key: "siNone", label: "--None--", value: "" },
  { key: "siAdvertisement", label: "Advertisement", value: "Advertisement" },
  { key: "siCustomer Event", label: "Customer Event", value: "Customer Event" },
  {
    key: "siEmployee Referral",
    label: "Employee Referral",
    value: "Employee Referral",
  },
  { key: "siGoogle AdWords", label: "Google AdWords", value: "Google AdWords" },
  { key: "siOther", label: "Other", value: "Other" },
  { key: "siPartner", label: "Partner", value: "Partner" },
  { key: "siPurchased List", label: "Purchased List", value: "Purchased List" },
  { key: "siTrade Show", label: "Trade Show", value: "Trade Show" },
  { key: "siWebinar", label: "Webinar", value: "Webinar" },
  { key: "siWebsite", label: "Website", value: "Website" },
];

const siPurposeOfFundingOptions = [
  { key: "siNone", label: "--None--", value: "" },
  {
    key: "siWorking Capital",
    label: "Working Capital",
    value: "Working Capital",
  },
  { key: "siInventory", label: "Inventory", value: "Inventory" },
  { key: "siPay Bills", label: "Pay Bills", value: "Pay Bills" },
  {
    key: "siMarketing/Advertising",
    label: "Marketing/Advertising",
    value: "Marketing/Advertising",
  },
  { key: "siNew Equipment", label: "New Equipment", value: "New Equipment" },
  { key: "siNew Location", label: "New Location", value: "New Location" },
  { key: "siExpansion", label: "Expansion", value: "Expansion" },
  { key: "siOther", label: "Other", value: "Other" },
];

const siRequestedFundingTimelineOptions = [
  { key: "siNone", label: "--None--", value: "" },
  { key: "siImmediately", label: "Immediately", value: "Immediately" },
  { key: "si1 Week", label: "1 Week", value: "1 Week" },
  { key: "si30 Days", label: "30 Days", value: "30 Days" },
  {
    key: "siMore Than 30 Days",
    label: "More Than 30 Days",
    value: "More Than 30 Days",
  },
];

const contactPreferenceOptions = [
  { key: "siNone", label: "--None--", value: "" },
  { key: "siPhone", label: "Phone", value: "Phone" },
  { key: "siEmail", label: "Email", value: "Email" },
];

const yesNoOptions = [
  { key: "None", label: "--None--", value: "" },
  { key: "Yes", label: "Yes", value: "Yes" },
  { key: "No", label: "No", value: "No" },
];

const aflStage = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Submitted", label: "Submitted", value: "Submitted" },
  { key: "Re-Submission", label: "Re-Submission", value: "Re-Submission" },
  { key: "Revisit Offer", label: "Revisit Offer", value: "Revisit Offer" },
  { key: "Auto-Decline", label: "Auto-Decline", value: "Auto-Decline" },
  { key: "Application In", label: "Application In", value: "Application In" },
  {
    key: "Application Missing Info",
    label: "Application Missing Info",
    value: "Application Missing Info",
  },
  { key: "On Hold", label: "On Hold", value: "On Hold" },
  { key: "Offer Sent", label: "Offer Sent", value: "Offer Sent" },
  { key: "Underwriting", label: "Underwriting", value: "Underwriting" },
  { key: "Approved", label: "Approved", value: "Approved" },
  { key: "Declined", label: "Declined", value: "Declined" },
  {
    key: "Agreement Requested",
    label: "Agreement Requested",
    value: "Agreement Requested",
  },
  { key: "Agreement Sent", label: "Agreement Sent", value: "Agreement Sent" },
  {
    key: "Agreement Signed",
    label: "Agreement Signed",
    value: "Agreement Signed",
  },
  { key: "Completed File", label: "Completed File", value: "Completed File" },
  { key: "Funded", label: "Funded", value: "Funded" },
  {
    key: "Renewal Prospecting",
    label: "Renewal Prospecting",
    value: "Renewal Prospecting",
  },
  {
    key: "Renewal Requested",
    label: "Renewal Requested",
    value: "Renewal Requested",
  },
  { key: "Closed Lost", label: "Closed Lost", value: "Closed Lost" },
  { key: "PAID IN FULL", label: "PAID IN FULL", value: "PAID IN FULL" },
  { key: "DEFAULT", label: "DEFAULT", value: "DEFAULT" },
  { key: "COLLECTIONS", label: "COLLECTIONS", value: "COLLECTIONS" },
];

const aflDeclineReason = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "California", label: "California", value: "California" },
  {
    key: "Deposit History Less Than 10 per Month",
    label: "Deposit History Less Than 10 per Month",
    value: "Deposit History Less Than 10 per Month",
  },
  { key: "Fraud", label: "Fraud", value: "Fraud" },
  { key: "Inactivity", label: "Inactivity", value: "Inactivity" },
  {
    key: "Insufficient Monthly Deposit Volume",
    label: "Insufficient Monthly Deposit Volume",
    value: "Insufficient Monthly Deposit Volume",
  },
  { key: "Massachusetts", label: "Massachusetts", value: "Massachusetts" },
  {
    key: "Merchant Background - TLO",
    label: "Merchant Background - TLO",
    value: "Merchant Background - TLO",
  },
  {
    key: "Merchant Passed",
    label: "Merchant Passed",
    value: "Merchant Passed",
  },
  {
    key: "Natural Disasters",
    label: "Natural Disasters",
    value: "Natural Disasters",
  },
  {
    key: "Negative Banking History",
    label: "Negative Banking History",
    value: "Negative Banking History",
  },
  {
    key: "Negative Prior Cash Advance History",
    label: "Negative Prior Cash Advance History",
    value: "Negative Prior Cash Advance History",
  },
  {
    key: "Not Qualified - Credit",
    label: "Not Qualified - Credit",
    value: "Not Qualified - Credit",
  },
  {
    key: "Not Qualified - Current Balance",
    label: "Not Qualified - Current Balance",
    value: "Not Qualified - Current Balance",
  },
  {
    key: "Not Qualified - Industry",
    label: "Not Qualified - Industry",
    value: "Not Qualified - Industry",
  },
  {
    key: "Not Qualified - Less Than 1 year in Business",
    label: "Not Qualified - Less Than 1 year in Business",
    value: "Not Qualified - Less Than 1 year in Business",
  },
  {
    key: "Not Qualified - Related Searches",
    label: "Not Qualified - Related Searches",
    value: "Not Qualified - Related Searches",
  },
  {
    key: "Not Qualified - Sales History",
    label: "Not Qualified - Sales History",
    value: "Not Qualified - Sales History",
  },
  {
    key: "Not Qualified - Too Many Open Positions",
    label: "Not Qualified - Too Many Open Positions",
    value: "Not Qualified - Too Many Open Positions",
  },
  {
    key: "Received Funding within last 30 days",
    label: "Received Funding within last 30 days",
    value: "Received Funding within last 30 days",
  },
];

const aflReasonLost = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Unresponsive", label: "Unresponsive", value: "Unresponsive" },
  {
    key: "Went with Competitor",
    label: "Went with Competitor",
    value: "Went with Competitor",
  },
  { key: "Long Process", label: "Long Process", value: "Long Process" },
  {
    key: "Declined by All Lenders",
    label: "Declined by All Lenders",
    value: "Declined by All Lenders",
  },
  { key: "Bad Offers", label: "Bad Offers", value: "Bad Offers" },
  { key: "Rate too High", label: "Rate too High", value: "Rate too High" },
  { key: "Term too Long", label: "Term too Long", value: "Term too Long" },
  { key: "Term too Short", label: "Term too Short", value: "Term too Short" },
  {
    key: "No Longer needs funds",
    label: "No Longer needs funds",
    value: "No Longer needs funds",
  },
  {
    key: "Low sales volume",
    label: "Low sales volume",
    value: "Low sales volume",
  },
  {
    key: "High Open Balance",
    label: "High Open Balance",
    value: "High Open Balance",
  },
  {
    key: "Restricted Industry",
    label: "Restricted Industry",
    value: "Restricted Industry",
  },
  {
    key: "Years in Business",
    label: "Years in Business",
    value: "Years in Business",
  },
  {
    key: "Past due balances",
    label: "Past due balances",
    value: "Past due balances",
  },
  {
    key: "Poor or no credit",
    label: "Poor or no credit",
    value: "Poor or no credit",
  },
  {
    key: "Start Up Business",
    label: "Start Up Business",
    value: "Start Up Business",
  },
  { key: "Tax liens", label: "Tax liens", value: "Tax liens" },
  { key: "Too much debt", label: "Too much debt", value: "Too much debt" },
  {
    key: "Merchant Declined",
    label: "Merchant Declined",
    value: "Merchant Declined",
  },
  { key: "Fraud", label: "Fraud", value: "Fraud" },
];

const aflUnderwritingStage = [
  { key: "--None--", label: "--None--", value: "" },
  {
    key: "PreQualification",
    label: "PreQualification",
    value: "PreQualification",
  },
  { key: "Move to Offer", label: "Move to Offer", value: "Move to Offer" },
  { key: "Approved", label: "Approved", value: "Approved" },
  { key: "Contract Out", label: "Contract Out", value: "Contract Out" },
  { key: "Contract In", label: "Contract In", value: "Contract In" },
  { key: "Underwriting", label: "Underwriting", value: "Underwriting" },
  {
    key: "Split Initiated",
    label: "Split Initiated",
    value: "Split Initiated",
  },
  { key: "Funded", label: "Funded", value: "Funded" },
  { key: "On Hold", label: "On Hold", value: "On Hold" },
  { key: "Declined", label: "Declined", value: "Declined" },
  { key: "Rejected", label: "Rejected", value: "Rejected" },
];

const aflUnderwritingSubStage = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Pre-Submission", label: "Pre-Submission", value: "Pre-Submission" },
  { key: "Missing Info", label: "Missing Info", value: "Missing Info" },
  { key: "Approval out", label: "Approval out", value: "Approval out" },
  { key: "Merchant Pass", label: "Merchant Pass", value: "Merchant Pass" },
  {
    key: "Beat by Competitor",
    label: "Beat by Competitor",
    value: "Beat by Competitor",
  },
  { key: "Declined", label: "Declined", value: "Declined" },
  {
    key: "UW Info Requested",
    label: "UW Info Requested",
    value: "UW Info Requested",
  },
  {
    key: "UCC in Progress",
    label: "UCC in Progress",
    value: "UCC in Progress",
  },
  {
    key: "Ready for Merchant Interview",
    label: "Ready for Merchant Interview",
    value: "Ready for Merchant Interview",
  },
  { key: "Slow Pay", label: "Slow Pay", value: "Slow Pay" },
  { key: "Defaulted", label: "Defaulted", value: "Defaulted" },
  { key: "In Collections", label: "In Collections", value: "In Collections" },
  { key: "Paid in Full", label: "Paid in Full", value: "Paid in Full" },
  {
    key: "Paid in Full DNR",
    label: "Paid in Full DNR",
    value: "Paid in Full DNR",
  },
  {
    key: "Wants a Call Back",
    label: "Wants a Call Back",
    value: "Wants a Call Back",
  },
  { key: "Dead", label: "Dead", value: "Dead" },
  {
    key: "Merchant Accepted",
    label: "Merchant Accepted",
    value: "Merchant Accepted",
  },
  { key: "Internal", label: "Internal", value: "Internal" },
];

const aflDealRating = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Tier 1+", label: "Tier 1+", value: "Tier 1+" },
  { key: "Tier 1", label: "Tier 1", value: "Tier 1" },
  { key: "Tier 2", label: "Tier 2", value: "Tier 2" },
  { key: "Tier 3", label: "Tier 3", value: "Tier 3" },
  { key: "Tier 4", label: "Tier 4", value: "Tier 4" },
];

const aflInterviewStatus = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Pending", label: "Pending", value: "Pending" },
  { key: "Incomplete", label: "Incomplete", value: "Incomplete" },
  { key: "Complete", label: "Complete", value: "Complete" },
];

const aflPriority = [
  { key: "--None--", label: "--None--", value: "" },
  { key: "Low", label: "Low", value: "Low" },
  { key: "Med", label: "Med", value: "Med" },
  { key: "High", label: "High", value: "High" },
];

//const = [ { key: '', label: '', value: ''},]

export {
  newLeadsourceOptions,
  siSubmissionStatusOptions,
  siIndustryOptions,
  siLegalEntityTypeOptions,
  siBusinessLocationOwnOrRentOptions,
  salutationOptions,
  siSubmissionSourceOptions,
  siPurposeOfFundingOptions,
  siRequestedFundingTimelineOptions,
  contactPreferenceOptions,
  yesNoOptions,
  aflStage,
  aflDeclineReason,
  aflReasonLost,
  aflUnderwritingStage,
  aflUnderwritingSubStage,
  aflDealRating,
  aflInterviewStatus,
  aflPriority,
  requestedFundingTimelineOptions,
  HowDoYouPreferToBeContacted,
  BusinessIndustry,
  LegalEntityType,
  PurposeOfFunding,
  LeadSource,
  offerDealType,
  offerPMTSchedule,
  paymentTransactionType,
  paymentStatus,
  paymentMethod,
  paymentUpfrontFeeOf,
  paymentDisbursementSchedule,
  paymentManagementFeeOf,
  syndicationDisbursementSchedule,
  syndicationUpfrontFeeOf,
  syndicationManagementFeeOf,
  syndicationPPayableStatus,
  TypeOfEntityOptions,
  IndustryOptions,
  OppStage,
  AmountRequestedPickList,
};
